<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Aws from "@/services/Aws";
    import TabTargetGroupInstances from "@/components/tabs/monitoring/tabTargetGroupInstances";
    import ModalRegisterInstanceToTargetGroup from "@/components/modals/monitor/aws/modalRegisterInstanceToTargetGroup";

    /**
     * Target Group Details component
     */
    export default {
        components: {
            Layout,
            PageHeader,
            TabTargetGroupInstances,
            ModalRegisterInstanceToTargetGroup
        },
        page: {
            title: "AWS Target Group",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "AWS Target Group",
                items: [
                    {
                        text: "AWS Target Groups",
                    },
                    {
                        text: "Target Group Details",
                        active: true,
                    },
                ],
                targetGroupData: [],
                registeredTargetGroupInstances: [],
                error: null,
                modalData: {},
                toggleProfile: false,
                showLoader: false
            };
        },
        async mounted() {
            this.getTargetGroupDetails()
        },
        methods: {

            getTargetGroupDetails() {

                this.showLoader = true;
                Aws.getTargetGroupDetails(this.$route.params.id).then((response) => {
                    this.targetGroupData = response.data.data;
                }).catch((error) => {
                this.error = error.response.data.error
                    ? error.response.data.error
                    : "";
                }).finally(() => {
                    this.showLoader = false;
                });
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },

            callModalRegisterInstance() {
                this.$bvModal.show("registerInstanceToTargetGroup_edit");
            },

            setRegisteredTargetGroupInstances(registeredInstances){
                this.registeredTargetGroupInstances = registeredInstances
            }
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!this.error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body" v-if="!showLoader">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>
                            </b-dropdown>
                            <i class="uil uil-angle-left float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getTargetGroupDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h3 class="mt-3 mb-1" >
                                {{targetGroupData.TargetGroupName}}
                            </h3>
                            <small class="mt-3 mb-1">{{targetGroupData.TargetGroupArn}}</small>
                            <p class="mt-2 mb-1"></p>
                            <div class="mt-2">
                                <div class="font-size-18">

                                </div>
                            </div>
                        </div>


                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">
                            <h5>Target Group Information</h5>
                            <div class="mt-3">
                                <p class="mb-2 text-strong">Protocol :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.Protocol}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Port :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" >{{targetGroupData.Port}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Target Type :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" >{{targetGroupData.TargetType}}</span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Load Balancers :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80" v-for="(loadBalancerName, index) in targetGroupData.LoadBalancerNames" :key="index"> {{loadBalancerName}} </span>
                                </p>
                            </div>

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Health Check Enabled :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.HealthCheckEnabled}}</span>
                                </p>
                            </div>


                            <hr />

                            <div class="mt-3">
                                <p class="mb-2 text-strong">Notes :</p>
                                <p class="font-size-16 mb-2 justify-content-between">
                                    <span class="mw-80">{{targetGroupData.note}}</span>
                                </p>
                            </div>

                            <b-button v-if="targetGroupData"
                                      class="mb-2 d-block m-auto mwb-11"
                                      variant="primary"
                                      title="Register Instance"
                                      @click="callModalRegisterInstance"
                                      :class="{ 'disabled': !targetGroupData.eligibleInstances || targetGroupData.eligibleInstances.length == 0 }"
                            ><i class="uil-desktop-cloud-alt"></i> Register Instance</b-button>

                        </div>
                    </div>
                    <div class="card-body d-flex align-items-center justify-content-center" v-else >
                        <b-spinner large></b-spinner>
                    </div>
                </div>
            </div>
            <div v-else class="col-xl-2">
                <div class="card h-100">
                    <div class="card-body">
                        <div class="text-center">
                            <b-dropdown
                                    class="float-end"
                                    variant="white"
                                    right
                                    menu-class="dropdown-menu-end"
                                    toggle-class="font-size-16 text-body p-0"
                            >
                                <template v-slot:button-content>
                                    <i class="uil uil-ellipsis-v"></i>
                                </template>

                            </b-dropdown>
                            <i class="uil uil-angle-right float-start font-size-20 text-body btn p-0" @click="toggle"></i>
                            <i class="uil uil-refresh font-size-20 btn p-0 link-primary" @click="getTargetGroupDetails()" title="Refresh Data"></i>
                            <div class="clearfix"></div>

                            <h5 class="mt-3 mb-1">
                                {{targetGroupData.TargetGroupName}}
                            </h5>
                            <small class="mt-3 mb-1">{{targetGroupData.TargetGroupArn}}</small>

                            <div class="mt-4">
                                <div class="font-size-18"></div>
                            </div>
                        </div>
                        <hr class="my-2" />

                        <div class="table-responsive mt-4 mb-0">

                        </div>
                    </div>
                </div>
            </div>

            <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">
                        <b-tab active>
                            <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Target Group Instances</span>
                            </template>
                            <template v-if="!showLoader">
                                <TabTargetGroupInstances :TargetGroupArn="this.$route.params.id"
                                                         @setRegisteredTargetGroupInstances="setRegisteredTargetGroupInstances" />
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>


                    </b-tabs>
                </div>
            </div>
        </div>
        <div v-else class="text-center mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>{{this.error}}</h4>
                </div>
            </div>
        </div>
        <!-- end row -->

        <!-- MODALS -->
        <ModalRegisterInstanceToTargetGroup
                :eligibleInstances=targetGroupData.eligibleInstances
                :registeredInstances=registeredTargetGroupInstances
                :targetGroupName=targetGroupData.TargetGroupName
                @onRefresh="getTargetGroupDetails()"
        ></ModalRegisterInstanceToTargetGroup>
        <!-- END  MODALS -->
    </Layout>
</template>
